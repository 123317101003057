.root {
    display: grid;
    gap: var(--spacing-4);
    border-bottom: 1px solid var(--color-neutral-300);
    padding: var(--spacing-4) 0;
    margin-bottom: var(--spacing-2);
    min-height: 260px;
}

.partCriteriasContainer {
    width: 100%;
    margin: 0 auto;
}

.partCriterias {
    composes: cleanList from global;
    margin-bottom: var(--spacing-1);
}

.linkageItem {
    display: flex;
    padding: var(--spacing-1);
    justify-content: space-between;

    &:nth-child(odd) {
        background-color: var(--color-neutral-200);
    }
}

.addToBasketSection {
    display: grid;
    gap: var(--spacing-1);
    grid-template-columns: auto auto;
    grid-template-areas: 
        "price add-to-basket"
        "stock-status add-to-basket";
    justify-content: space-between;
    align-items: center;
}

.productLink {
    color: inherit;
    display: block;
    text-decoration: none;
    position: relative;
}

.splashesContainer {
    z-index: var(--z-index-1);
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
}

.priceArea {
    grid-area: price;
}

.sotckStatus {
    grid-area: stock-status;
}

.addToBasket {
    grid-area: add-to-basket;
}

.skeletonImageContainer {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--spacing-4);
}

.addFavoriteBtn {
    margin-top: 5px;
    position: relative;
    left: -5px
}

@container (max-width: 749px) {
    .price {
        justify-items: left !important;
    }
}

@container (min-width: 750px) {
    .root {
        padding: var(--spacing-5) var(--spacing-2);
        grid-template-columns: 350px 1fr auto;
        align-items: center;
    }

    .linkagesContainer {
        max-width: 500px;
    }

    .addToBasketSection {
        justify-content: end;
        justify-items: end;
        grid-template-columns: auto;
        grid-template-areas: 
            "price"
            "stock-status"
            "add-to-basket";
        order: 3;
        min-width: 200px;
    }

    .stockStatus {
        display: flex;
        justify-content: flex-end;
        margin-bottom: var(--spacing-4)
    }

    .partCriteriasContainer {
        max-width: 500px;
    }

}